<template>
  <div class="h-screen flex items-center w-full -mt-20">
    <div class="w-80 md:w-96 mx-auto my-auto text-center">
      <p class="font-bold text-lg md:text-2xl">One last step!</p>
      <p class="font-extralight text-base md:text-xl pt-2 pb-2">
        We've sent a One-Time Password to
        <span class="text-orange font-normal">{{ email }}</span
        >. Copy the code & paste it below to verify your email.
      </p>
      <button
        class="mx-auto pb-12 text-lg text-orange font-bold"
        @click="
          () => {
            editEmail = true;
            key++;
          }
        "
      >
        edit email
      </button>

      <modal :active="editEmail" :key="key">
        <div class="w-80">
          <h2 class="text-xl font-bold mt-4 -mb-2 text-center">
            Edit your email
          </h2>
          <form-input
            v-model:value="email"
            label=""
            placeholder="add your email"
            email
          />
          <div class="w-full flex justify-end">
            <button
              class="
                py-2
                mt-4
                px-5
                relative
                text-center
                rounded-2xl  
                font-bold
                bg-orange
                text-white
              "
              @click="
                () => {
                  save();
                  resend();
                }
              "
            >
              resend
            </button>
          </div>
        </div>
      </modal>
      <form-input
        v-model:value="otp"
        placeholder="------"
        label="6-Digit Code:"
        otp
      />

      <button
        id="button"
        type="submit"
        class="
          w-full
          px-6
          py-3
          mt-3
          text-lg text-white
          transition-all
          duration-150
          ease-linear
          rounded-lg
          font-bold
          shadow
          outline-none
          bg-orange
          hover:shadow-xl
          focus:outline-none
          capitalize
        "
        @click="e3temed"
      >
        Log in
      </button>

      <button
        v-if="failed"
        class="mx-auto py-6 text-lg text-orange font-bold"
        @click="resend()"
      >
        resend email
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import me3temed from "../../me3temed.js";
import formInput from "../formInput.vue";
import modal from "../modal.vue";
import mixpanel from "mixpanel-browser";

export default {
  components: { formInput, modal },
  data() {
    return {
      otp: "",
      email: this.$store.state.onboarding_info.user.email || "",
      editEmail: false,
      key: 0,
      error: false,
      failed: false,
    };
  },
  computed: {
    ...mapState(["onboarding_info"]),
  },
  methods: {
    ...mapMutations(["fireToast", "addOnboardingUser"]),
    ...mapActions(["create_user"]),

    save() {
      let user = this.onboarding_info.user;
      user.email = this.email;
      this.addOnboardingUser(user);
      this.editEmail = false;
      this.key++;
    },

    resend() {
      me3temed.OTP({ email: this.email });
      this.failed = false;
    },

    async createUser() {
      // create user
      await this.create_user(this.onboarding_info.user).then(() => {
        mixpanel.track("Sign up completed", {
          user: this.onboarding_info.user,
        });
        // route to correct page
        if (this.onboarding_info.is_tutor) this.$router.push(`/apply`);
        else this.$router.push(`/${this.email}/tutors`);
      });
    },
    async e3temed() {
      await me3temed.e3temed({ otp: this.otp }).then(async (data) => {
        if (data.success) {
          // OTP successful
          this.failed = false;
          await this.createUser();
        } else {
          this.failed = true;

          this.fireToast({
            text: `Invalid OTP!`,
            type: "error",
          });
        }
      });
    },
  },
};
</script>
