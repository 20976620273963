<template>
    <page-layout title="Verify your email">
        <OTP />
    </page-layout>
</template>

<script>
import OTP from '../components/onboarding/OTP.vue'
import PageLayout from '../components/base/credentialsLayout.vue'
    export default {
        components: { OTP, PageLayout }
    }
</script>